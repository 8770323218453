import { Container, Heading, Text, Box, Em } from "@chakra-ui/react";
import React from "react";
import { Link } from 'react-router-dom';

export const PolicyPediatricianInformationPersonalDataPage: React.FC = () => {
  return (
    <Container maxW="container.lg" py={20}>
      {/* Titoli principali */}
      <Heading as="h1" textAlign="center" >
        INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI
      </Heading>
      <Heading as="h2" textAlign="center" mb={10}>
        in applicazione del Regolamento europeo n. 2016/679 e prestazione del consenso al trattamento
      </Heading>

      {/* Corpo dell'informativa */}
      <Box>
        <Text mb={4} textAlign="justify">
          Egregio Dottore, gentile Dottoressa, < br/> in relazione alla sottoscrizione del contratto per l’utilizzo della piattaforma <strong>KIDDOCARE</strong> e nella Sua qualità di interessato, Le sottoponiamo la seguente informativa al fine del rilascio del Suo consenso al trattamento dei dati.
           <br/>L’informativa viene resa per adempiere agli obblighi imposti dal Regolamento europeo sulla protezione dei dati personali (Regolamento Europeo 2016/679).
        </Text>

        <Text mb={4} textAlign="justify">
          <strong> Il Titolare del trattamento </strong>, cioè chi assume le decisioni in merito alle modalità e alle finalità del trattamento, è <strong>AIHEALTH srl</strong> con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: <i>amministrazione@ai-health.it</i>, PEC: <i>aihealth@pec.it</i>. 
          <br/><strong>Il Responsabile della Protezione Dati</strong> (o DPO – <Em>Data Protection Officer</Em>) è contattabile all’indirizzo e-mail: <i>dpo@ai-health.it</i>.
        </Text>

        <Text mb={4} textAlign="justify">
          I Suoi dati personali vengono raccolti per l’accesso e l’utilizzo dell’applicazione <strong>KIDDOCARE</strong>. Essi sono trattati con modalità informatico-telematiche al fine di fornirle la possibilità di utilizzare l’applicazione <strong> KIDDOCARE </strong> nelle sue particolari funzioni, secondo le modalità specificate nelle   <Link to="../pediatrician/terms-conditions" >
          <strong>Termini e Condizioni</strong></Link> consultabili accedendo all’applicazione.
          <br/> I Suoi dati personali saranno trattati e archiviati dal Titolare al fine di consentirLe l’utilizzo della applicazione, nell’ambito delle finalità sopra elencate, in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.

        </Text>

        <Text mb={4} textAlign="justify">
          Si precisa che, previo Suo consenso, il trattamento dei Suoi dati potrà essere effettuato anche mediante sistemi di Intelligenza Artificiale, sia al fine di supportare la Sua prestazione sanitaria, vuoi sotto il profilo diagnostico che terapeutico, sia sotto il profilo organizzativo, nonché - previa anonimizzazione degli stessi - a fini di ricerca scientifica e statistica.
        </Text>

        <Text mb={4} textAlign="justify">
          Per sistema di Intelligenza Artificiale (IA) si intende un sistema automatizzato progettato per funzionare con livelli di autonomia variabili e che deduce dalle informazioni che riceve come generare previsioni, contenuti, raccomandazioni o decisioni.
        </Text>
        <Text mb={4} textAlign="justify">
         
          Il trattamento dei dati personali per finalità di sviluppo di sistemi di IA prevede che tali dati siano utilizzati per l’addestramento del sistema e per la costruzione di algoritmi (modelli matematici che interpretano i dati). <br/> Tali sistemi possono essere utilizzati, sotto la responsabilità del curante autorizzato, a fini di ausilio conoscitivo per agevolare la diagnosi (prevenzione, classificazione e stratificazione delle condizioni patologiche dei pazienti) e la comprensione di meccanismi fisiopatologici, per valutare quale trattamento di cura possa essere più appropriato e quale possa essere la risposta al trattamento e l’evoluzione della patologia.
          Inoltre, sotto il profilo della ricerca scientifica, la raccolta ed analisi di grandi quantità di dati sanitari permetterà lo sviluppo di modelli predittivi personalizzati, che aiuteranno ad individuare precocemente i pazienti a rischio di potenziali complicanze.
          Un’altra funzionalità riguarda la possibilità di migliorare l’organizzazione e l’efficienza delle prestazioni erogate dal pediatra, anche mediante l’interazione con l’agenda dei suoi appuntamenti e le giornate e le fasce orarie di reperibilità.

        </Text>

        <Text mb={4} textAlign="justify">
          Inoltre, previo suo consenso, i Suoi dati personali potranno essere utilizzati dal Titolare per le seguenti finalità:
          <ul>
            <li> - per fornirLe su Sua richiesta informazioni e notizie sui prodotti, sulle attività e sulle iniziative imprenditoriali di <strong>AIHEALTH srl </strong> mediante mail, telefono o chat;</li>
            <li> - per comunicazione commerciale e promozionale (<Em>marketing diretto</Em>), trattamento facoltativo che consente al Titolare il contatto diretto per comunicazioni promozionali e commerciali (es. newsletter) e l’invio di materiale informativo o il compimento di ricerche di mercato o sondaggi di opinione; </li>
            <li> - per analisi ed elaborazioni sui comportamenti (<Em>profilazione)</Em>. Si tratta di trattamento facoltativo volto ad analizzare o prevedere gli interessi, le preferenze, il comportamento di consumo, l'ubicazione del cliente, per comporre categorie individuali o di gruppo, a livello statistico o di dettaglio finalizzato a migliorare la conoscenza dei bisogni e delle aspettative degli Interessati nei confronti del Titolare; </li>
            <li> - per comunicazione commerciale e promozionale dei dati verso società terze per proprie attività di marketing diretto. Anche in questo caso si tratta di trattamento facoltativo.
            </li>
          </ul>
        </Text>
       
        <Text mb={4} textAlign="justify"> 
        I dati saranno trattati con modalità informatico-telematiche e archiviati dal Titolare per le finalità sopra indicate e in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.
        <br/> Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
        <br/>Tali operazioni di trattamento dei dati personali avvengono rispettando scrupolosamente i principi di riservatezza e di sicurezza richiesti dalla legge sopra richiamata ed ispirandosi ai principi di correttezza e liceità di trattamento.
        </Text>
        

        <Text mb={4} textAlign="justify"> 
        Con riguardo alla prima finalità (utilizzo della piattaforma <strong> KIDDOCARE </strong>), il conferimento dei dati e il consenso al relativo trattamento sono facoltativi, ma rappresentano condizioni necessarie per l’utilizzo della applicazione e l’eventuale rifiuto potrà comportare per il Titolare l’impossibilità di consentirLe l’utilizzo della applicazione <strong> KIDDOCARE </strong>, nonché l’accesso alla piattaforma collegata. <br/>
        Con riguardo ai sistemi di intelligenza artificiale, la mancanza di alcuni dati (che costituiscono gli “esempi” o elementi di base per l’apprendimento del sistema) potrebbe portare ad una rappresentazione non accurata di alcune condizioni e/o patologie con conseguenze sull’efficienza e sull’accuratezza del sistema di IA o limiti nel suo utilizzo e/o nella sua funzionalità.
        <br/>Con riferimento alla seconda finalità (marketing), l’utilizzo dei Suoi dati è subordinato al Suo consenso.
        <br/>Nello specifico: 
        <br/> - il trattamento dei dati personali per finalità di comunicazioni informative, commerciali e promozionali, nonché per profilazione è lecito qualora l’Interessato abbia espresso il proprio preventivo e specifico consenso.
        <br/> - Il trattamento dei dati personali per comunicazioni commerciali e promozionali -marketing diretto - compiuto da società terze è lecito qualora l’Interessato abbia espresso il consenso esplicito.
        <br/>Inoltre, i dati personali degli Interessati potranno essere, altresì, trattati lecitamente per adempiere a obblighi previsti da leggi, regolamenti o normative sia nazionali che comunitarie o per rispondere ad istanze delle Autorità pubbliche e private legittimate. 
        <br/>Ricorrendo queste ultime finalità la normativa applicabile non richiede un’esplicitazione del consenso dell’Interessato.

        
        </Text>

        <Text mb={4} textAlign="justify">
          Con riguardo alla durata del trattamento, i dati da Lei conferiti per il contratto finalizzato all’utilizzo della piattaforma <strong>KIDDOCARE</strong>  saranno trattati per tutta la durata dello stesso e per il periodo richiesto dall’espletamento degli obblighi di legge.
          <br/>I dati conferiti a fini di marketing saranno trattati fino a revoca espressa.
          <br/> Previo Suo consenso, le informazioni acquisite nel corso dell’utilizzo della piattaforma potranno essere trattate a fini di ricerca scientifica e di addestramento dei sistemi di intelligenza artificiale <u>ma solo in modalità anonima.</u>
  </Text>




        <Text mb={4} textAlign="justify">
        Per il corretto funzionamento della piattaforma, i dati da Lei conferiti saranno comunicati ai soggetti autorizzati dal Titolare, quali:
        <br/> - Gli amministratori di sistema e i fornitori dei servizi informatici, in qualità di responsabili del trattamento.
  </Text>

        <Text mb={4} textAlign="justify">
          La informiamo che Lei ha diritto di:
          <ul>
            <li> - chiedere al Titolare l’accesso ai propri dati personali, la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati che la riguardato o di opporsi al loro trattamento, inoltre chiedere di trasmetterli a un altro Titolare del trattamento (portabilità dei dati);
            </li>
            <li> - revocare il consenso, in qualsiasi momento, laddove il trattamento dei suoi dati personali si basi sul consenso. Potrà revocare il Suo consenso inviando una comunicazione al Titolare. Si precisa che la mancanza del consenso al trattamento  comporterà per il Titolare l’impossibilità di consentirLe l’utilizzo della applicazione <strong>KIDDOCARE</strong> nonché l’accesso alla piattaforma collegata;
            </li>
            <li> - Con riferimento al diritto di opposizione al trattamento dei suoi dati per finalità di marketing, oltre a revocare il Suo consenso, può sempre liberamente e gratuitamente segnalare al Titolare che desidera ricevere solo eventuali comunicazioni effettuate con modalità tradizionali (posta cartacea o chiamate con operatore).</li>
            <li>   - proporre un reclamo all’Autorità di controllo ovvero l’autorità per la protezione dei dati personali nazionale o di qualunque altro paese dell’UE</li>
          </ul>
        </Text>

        <Text mb={4} textAlign="justify">
        I Suoi dati personali che saranno oggetto di trattamento per la finalità di utilizzo della piattaforma <strong>KIDDOCARE</strong> sono i seguenti:
        <br/> - Titolo accademico, 
          <br/> - Nome,<br/> - Cognome, <br/> - Luogo di nascita, <br/> - Codice fiscale,<br/> - Email,<br/> - PEC, <br/> - Telefono, <br/>  - Specializzazione, <br/>  - Password, <br/>  - Numero di iscrizione all’Albo,  <br/> - Regione di convenzione
          <br/> - Codice ASL di convezione
          <br/> - Partita IVA
          <br/> - Domicilio fiscale
          <br/> - Indirizzo di studio
          <br/> - IBAN
          <br/> - Credenziali Sistema Tessera Sanitaria
          <br/> - Foto Carta d’identità
          <br/> - Foto Timbro di studio
          <br/> - Foto firma.
          <br/> e altri dettagli necessari al funzionamento della piattaforma.
        </Text>

        <Text mb={4} textAlign="justify">
        I Suoi dati personali che saranno oggetto di trattamento per la finalità di marketing sono i seguenti: 


          <br/> - Nome
          <br/> - Cognome
          <br/> - Indirizzo email
          <br/> - N. di telefono
          <br/> - Frequenza e orari prevalenti di utilizzo della APP
          <br/> - Modalità di utilizzo della APP.
         
        </Text>


        

      </Box>
    </Container>
  );
};
