import { Container, Heading, Text, Em } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export const TermsConditionsParent: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>
   
         <Heading mt={'50'}> Kiddocare – Utente genitore
         </Heading>
         <Heading mt={'2'} mb={'20'}> Premesse</Heading>
   
         <Text mt={4} textAlign="justify">
         Il presente documento riporta i termini contrattuali e le condizioni generali di utilizzo dell’applicazione <strong>Kiddocare</strong>, al fine del corretto utilizzo della infrastruttura informatico-telematica che consente al pediatra di interagire con il genitore (o altro esercente la potestà genitoriale o la rappresentanza legale) del paziente minore al fine della sua cura e della sua assistenza.
         </Text>
         <Text mt={4} textAlign="justify">
         Tale applicazione si prefigge, pertanto, di migliorare la comunicazione tra il pediatra e il genitore e di rendere la prestazione sanitaria di cura e di assistenza del minore più efficace e tempestiva.
         </Text>
   
         <Text mt={4} textAlign="justify">
         L’utilizzo della applicazione e della piattaforma <strong>Kiddocare</strong> è fornito al pediatra da <strong>AIHEALTH srl</strong> con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail:amministrazione@ai-health.it, PEC: aihealth@pec.it.
         </Text>
       
         <Text mt={4} textAlign="justify"> Le premesse costituiscono parte integrante del contenuto contrattuale.
         </Text>
   
         <Text mt={4} textAlign="center"> <strong>Termini contrattuali e condizioni d’utilizzo per utente <Em>genitore</Em> </strong>
         </Text>
         <Text mt={4} textAlign="center"> <strong>Termini contrattuali</strong></Text>
   
   
   
         <Text mt={4} textAlign="justify" pl={7}>1.  <strong>  Oggetto del contratto.</strong>  Il presente contratto ha ad oggetto la fornitura a favore del genitore (o altro esercente della potestà genitoriale) della applicazione Kiddocare al fine della cura e della assistenza del paziente minore. Il dettaglio dei servizi e delle funzionalità che caratterizzano Kiddocare 
          <Link to="../parents/Allegato1" >
         <strong> (Allegato 1 del contratto) </strong> </Link> 

         </Text>
         <Text mt={4} textAlign="justify" pl={7}>2.  <strong>  Corrispettivi. </strong>  L’utilizzo dell’applicazione da parte del genitore è subordinato al pagamento di un canone annuale da effettuarsi mediante carta di credito/debito utilizzando l’apposita funzione presente sul sito Kiddocare.eu 
         <br/> I corrispettivi dei servizi sono pari a:
         <Text mt={4} textAlign="justify"pl={9}>
                  - 12 euro canone annuale Kiddocare con un solo minore assistito.
                  </Text> 
                  <Text mt={4} textAlign="justify"pl={9}> - 18 euro canone annuale Kiddocare con più minori assistiti. </Text>
                  <Text mt={4} textAlign="justify"pl={9}>- Sulle prestazioni a pagamento (televisite, certificati ecc.), ricevute con l’utilizzo della piattaforma, sarà applicata una commissione pari al 4% oltre iva, come per legge, calcolato sul valore della prestazione stessa. </Text>

         </Text>
         <Text mt={4} textAlign="justify" pl={7}>3.  <strong>  Registrazione.</strong> Per usufruire delle funzionalità dell’applicazione, il genitore deve registrarsi fornendo, in modo corretto, esaustivo e veritiero, tutti i dati richiesti nel relativo <Em>form</Em> di registrazione ad esso specificamente riservato, in particolare il genitore deve obbligatoriamente:
         </Text>
         <Text mt={4} textAlign="justify"pl={9}>
         -  accettare integralmente i termini contrattuali e le condizioni d’uso;
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         -  previa lettura della relativa informativa  <Link to="../parents/information-personal-data-child" >
         <strong>(Allegato 3)</strong> </Link>, rilasciare il consenso al trattamento dei dati personali e sanitari del minore necessari per la prestazione sanitaria;

         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         -  previa lettura della relativa informativa <Link to="../parents/information-personal-data" >
         <strong>(Allegato 4)</strong> </Link> , rilasciare il consenso al trattamento dei dati personali, ed eventualmente sanitari, propri, necessari per la prestazione sanitaria a favore del minore e più ampiamente per l’esecuzione del contratto;
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - previa lettura della relativa informativa e qualora interessato, rilasciare il 
         consenso a fini di marketing 

         <Link to="../parents/information-personal-data-bis" >  
           <strong> (Allegato 5)</strong> </Link>.
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         Una volta accettati i termini contrattuali e le condizioni d’uso e rilasciati i consensi necessari, il genitore potrà procedere alla registrazione e ottenere il proprio account per interagire con il pediatra.
        <br/><strong>Il genitore espressamente dichiara di accettare le presenti condizioni contrattuali e di procedere all’utilizzo della applicazione Kiddocare avendo previamente informato l’altro genitore e con il suo consenso.
        </strong>
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         4. <strong> Comunicazione di invito al genitore. </strong> Trattamento dei dati. Durata del trattamento. I dati personali del minore e del genitore saranno trattati secondo quanto riportato nelle informative privacy disponibili nell' 
         <Link to="../parents/information-personal-data-child" >
         <strong>Allegato 3</strong></Link>. <br/>
Tutti i dati che riguardano il minore e il genitore saranno trattati per tutta la durata del presente rapporto contrattuale e per il periodo richiesto dall’espletamento degli obblighi di legge. I dati del genitore conferiti a fini di marketing saranno trattati fino a revoca espressa da parte del genitore stesso.
<strong> <br/>Il genitore dichiara di avere previamente informato l’altro genitore e di avere accettato il trattamento dei dati del minore con il consenso dell’altro genitore.
</strong>
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         5. <strong> Utilizzo dei dati a fini di ricerca scientifica. </strong>  Previo consenso del genitore e con l’accordo del pediatra, le informazioni acquisite nel corso dell’utilizzo della piattaforma potranno essere trattate a fini di ricerca scientifica e di addestramento dei sistemi di intelligenza artificiale <u>ma solo in modalità anonima. </u>

         </Text>
   
         <Text mt={4} textAlign="justify" pl={7}>
         6. <strong>Community degli utenti genitori.   </strong>  L’adesione e la fruizione della piattaforma consentirà agli utenti genitori di aderire ad una Community digitale fruibile attraverso la piattaforma Kiddocare. Obiettivi della Community sono: la possibilità di ricevere informazioni e indicazioni di carattere generale  dal pediatra, ricevere studi,  statistiche e comunicazioni della community, inviare contributi su temi specifici alla community.
         </Text>
   
         <Text mt={4} textAlign="justify" pl={7}>
         7. <strong>Regole e limiti di utilizzo. </strong> La piattaforma presenta regole e limiti di utilizzo che l’utente accetta, quali per esempio: non potranno essere effettuati più di 5 accessi al minuto, la singola sessione di collegamento alla piattaforma non potrà eccedere la durata di un’ora, le videochiamate hanno una durata massima di 20 minuti, i messaggi e i relativi allegati non potranno superare la dimensione di 1 MByte, la memoria a disposizione dell’utente per l’archiviazione dei documenti non potrà superare i 2 GByte. 
        <br/> Si precisa che l’applicazione potrà essere utilizzata solo se è stata adottata anche dal pediatra e previa accettazione del paziente da parte del pediatra.
        <br/><strong>AIHEALTH srl </strong>si adopererà per assicurare che l’applicazione sia disponibile ininterrottamente 24 ore al giorno, e comunque nei normali orari di studio del pediatra, tuttavia l’accesso all’applicazione potrà essere sospeso temporaneamente e senza preavviso in caso di guasto del sistema, manutenzione, riparazioni o per 
        ragioni del tutto estranee alla volontà di <strong>AIHEALTH srl</strong> o per eventi di forza maggiore, o per impedimenti del medico o di un qualunque altro terzo e <strong>AIHEALTH srl</strong> non potrà in alcun modo essere ritenuta responsabile.

        
        </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         8. <strong> Utilizzo dell’Intelligenza artificiale.</strong>  Previo consenso dell’utente, verranno impiegati sistemi di Intelligenza Artificiale, sia al fine di supportare la prestazione sanitaria, vuoi sotto il profilo diagnostico che terapeutico, sia per finalità organizzative, nonché - previa anonimizzazione degli stessi - a fini di ricerca scientifica e statistica. Per sistema di Intelligenza Artificiale (IA) si intende un sistema automatizzato progettato per funzionare con livelli di autonomia variabili e che deduce dalle informazioni che riceve come generare previsioni, contenuti, raccomandazioni o decisioni. L’uso di informazioni e il trattamento dei dati personali per finalità di sviluppo di sistemi di IA prevede che tali informazioni e dati siano utilizzati per l’addestramento del sistema e per la costruzione di algoritmi (modelli matematici che interpretano i dati). I sistemi di IA possono essere utilizzati, sotto la responsabilità del curante autorizzato, a fini di ausilio conoscitivo per agevolare le prestazioni sanitarie. Inoltre, sotto il profilo della ricerca scientifica, la raccolta ed analisi di grandi quantità di dati sanitari permetterà lo sviluppo di modelli predittivi personalizzati, che aiuteranno ad individuare precocemente i pazienti a rischio di potenziali complicanze. Un’altra funzionalità riguarda la possibilità di migliorare l’organizzazione e l’efficienza delle prestazioni erogate dal pediatra, anche mediante l’interazione con l’agenda dei suoi appuntamenti e le giornate e le fasce orarie di reperibilità.
         </Text>
   
         <Text mt={4} textAlign="justify" pl={7}>
         9. <strong> Esonero da responsabilità per eventi dovuti a forza maggiore, caso fortuito, fatto di terzi.</strong> <br/> <strong>AIHEALTH srl </strong> 
         non potrà essere ritenuta responsabile in caso di interruzioni del servizio 
         causate da problemi tecnici alla stessa non imputabili o da forza maggiore. 
         <br/>L’utente genitore esonera pertanto sin d’ora <strong>AIHEALTH srl</strong> da qualsivoglia responsabilità conseguente a mancato contatto con il pediatra derivante da mancato funzionamento della infrastruttura per fatto del terzo, caso fortuito o forza maggiore.
        <br/>Il genitore si impegna, inoltre, espressamente, e senza riserva alcuna, a tenere <strong>AIHEALTH srl</strong> indenne e manlevata da qualsiasi danno, obbligo risarcitorio e/o sanzione in qualsiasi modo collegata alla violazione da parte sua delle regole sulla registrazione della applicazione ovvero conseguenti a non veridicità delle informazioni rese, o derivanti dalla violazione delle più idonee misure di conservazione delle credenziali di registrazione, ovvero da incapacità tecnologica nell’utilizzo dell’app.
        </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         10. <strong> Titolarità del diritto d’autore. Kiddocare</strong>  è tutelata dal diritto d’autore. <strong>AIHEALTH srl </strong> dichiara di essere titolare e/o licenziataria di tutti i diritti di proprietà intellettuale relativi e/o afferenti all’applicazione. Pertanto tutti i marchi, figurativi o nominativi e tutti gli altri segni, nomi commerciali, marchi di servizio, marchi denominativi, denominazioni commerciali, illustrazioni, immagini, loghi, contenuti relativi all’applicazione sono e rimangono di titolarità di <strong>AIHEALTH srl </strong> o dei suoi licenziatari e sono protetti dalle leggi vigenti sui marchi e dai relativi trattati internazionali. Le condizioni non concedono al medico alcuna licenza d’uso relativa all’applicazione e/o a singoli contenuti e/o materiali ivi disponibili che il pediatra stesso non abbia immesso, se non diversamente disciplinato. Eventuali riproduzioni in qualunque forma dei testi esplicativi e dei contenuti dell’applicazione, qualora non autorizzate, saranno considerate violazioni del diritto proprietà intellettuale ed industriale del titolare.
   All’utente è fatto divieto copiare, modificare, utilizzare commercialmente il software che è alla base del funzionamento dell’applicazione, così come gli è fatto divieto di cercare di decifrare il codice sorgente e/o di tentare di apportarvi modifiche e/o creare lavori da esso derivati a scopo di lucro.
     </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         11.<strong>  Modifiche ai termini contrattuali e alle condizioni d’uso. Accettazione. </strong>  Eventuali modifiche ai presenti termini contrattuali saranno comunicate agli utenti mediante l’applicazione e dovranno essere accettate dal medico al primo accesso successivo alla modifica ai fini del mantenimento del rapporto contrattuale. In caso di mancata accettazione il contratto si intenderà risolto.
   In ogni caso, <strong>AIHEALTH srl </strong>  ai fini del miglioramento dell’applicazione <strong>Kiddocare</strong>, anche in base ai feedback ricevuti di volta in volta dagli utenti, si riserva fin d’ora il diritto di variare l’interfaccia grafica dell’applicazione, l’organizzazione dei suoi contenuti, nonché ogni altro aspetto che caratterizzi la funzionalità e la gestione dell’applicazione medesima, comunicando quanto prima al medico le relative istruzioni di utilizzo.
     </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         12.<strong>  Durata del contratto.</strong>  Il presente contratto avrà durata pari a mesi 12 e sarà oggetto di rinnovo automatico salvo disdetta.   </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         13.<strong> Disdetta e rinnovo.</strong>   Qualora, entro il trentesimo giorno antecedente la scadenza del contratto una delle parti non comunichi all’altra tramite PEC la propria disdetta, il contratto si prorogherà per altri dodici mesi. Ulteriori rinnovi di pari durata si avranno in caso di mancata disdetta, sempre con 30 giorni di preavviso sulla scadenza del periodo di rinnovo.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         14. <strong> Diritto di recesso.</strong>  Entro il temine di 14 giorni dall’attivazione dell’App, il genitore potrà esercitare il diritto di “ripensamento” (diritto di recesso) utilizzando l’apposito modulo <Link to="../parents/Allegato2" >
         <strong>Allegato 2</strong></Link>. <br/> Il rimborso del corrispettivo pagato sarà effettuato utilizzando lo stesso metodo di pagamento adottato per l’acquisto.  
         <br/>Prima della scadenza del termine naturale del contratto, il genitore potrà comunicare all’altra parte la propria intenzione di recedere dal contratto mediante PEC o raccomandata postale senza necessità di giustificazione. In tal caso, salvo quanto previsto al punto precedente, il genitore non avrà diritto alla restituzione del corrispettivo già pagato.
        </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         15. <strong> Clausola risolutiva espressa.  </strong> Ai sensi dell’art. 1564 c.c., un inadempimento di notevole importanza da parte del genitore tale da minare la fiducia tra i contraenti comporterà la risoluzione del contratto e la conseguente disattivazione dei servizi. In particolare, si considererà causa di risoluzione il mancato pagamento, entro 15 giorni dalla scadenza del termine naturale del contratto, del corrispettivo per il rinnovo nonché l’uso improprio dei servizi da parte del genitore, qualora tale uso produca danni o sia diretto a violare norme di legge, diritti di <strong>AIHEALTH srl</strong> o di terzi.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         16. <strong> Cancellazione dei dati del paziente. </strong> In caso di disdetta, di recesso o di risoluzione contrattuale, il genitore avrà a disposizione 60 giorni di tempo per scaricare e memorizzare in propri archivi i dati di cui è titolare. Scaduto il termine indicato, i dati verranno cancellati. Dopo 90 dalla disdetta, dal recesso o dalla risoluzione, l’account fornito al genitore potrà essere definitivamente chiuso.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         17. <strong> Legge applicabile.  </strong>  Il presente accordo è regolato dalla legge italiana e dalle norme dell’Unione Europea, nonché dalle convenzioni internazioni di pertinenza.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         18. <strong> Clausola arbitrale e foro competente.</strong>  In caso di controversie concernenti la validità, l’interpretazione, l’esecuzione e la risoluzione del presente accordo o ad esso collegate, l’utente si impegna alla ricerca di un componimento bonario, anche mediante il ricorso alla Camera arbitrale di Milano. Qualora la controversia non dovesse risolversi bonariamente e comunque entro 6 mesi dall’inizio della stessa, la controversia sarà di competenza del Tribunale di Milano.
        <br/>Si precisa, inoltre, quanto segue:
  </Text>
         <Text mt={4} textAlign="Justify" pl={7}>
         19. L’applicazione consente al pediatra e soltanto nei casi dallo stesso indicati, di attivare la funzionalità “SOS” finalizzata a contattare il pediatra con celerità in caso di maggiore urgenza in base al giudizio del genitore. <strong><u>Tuttavia si avverte che, per sua natura, l’applicazione Kiddocare non può essere utilizzata nelle situazioni di emergenza sanitaria. In questi casi il genitore del paziente è tenuto ad utilizzare il Servizio di Urgenza-Emergenza Medica (chiamata 118 o 112 dove attivata), i presidi di Pronto Soccorso o i Centri di Assistenza Urgenza messi a disposizione dal Servizio Sanitario.</u></strong>
 </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         20. L’applicazione non può essere usata per la farmacovigilanza, intesa come segnalazione di eventi avversi gravi e significativi.
         </Text>
         <br/>
         <Text textAlign={'center'}><strong>Condizioni generali di Utilizzo</strong> </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         21. Al fine di poter utilizzare l’applicazione e la piattaforma Kiddocare sono necessari all’utente l’accesso al web e gli opportuni <Em> devices smartphone/tablet</Em> ad esso collegati. I dati verranno trasmessi in modalità sicura attraverso il protocollo https.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         22. L’utente potrà accedere alla piattaforma tramite <Em>user name</Em> e <Em>password</Em> che deve avere le seguenti caratteristiche.
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - lunghezza minima di 8 caratteri alfanumerici;</Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - contenere almeno un carattere numerico, un carattere alfabetico in maiuscolo, un carattere alfabetico in minuscolo e un carattere speciale;
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - non deve richiamare significati facilmente intuibili da terzi (es. parole o frasi comuni di senso compiuto, nomi persona,  indirizzi di casa, date di nascita ecc..);
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - non deve contenere numeri o lettere in sequenza;
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - deve essere diversa dalle ultime 5 password precedentemente utilizzate;
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         - deve essere obbligatoriamente cambiata  almeno ogni 90 giorni.
         </Text>
         <Text mt={4} textAlign="justify" pl={9}>
         Al primo accesso l’utente dovrà provvedere alla personalizzazione della propria <Em>password.</Em> Poiché vengono trattati dati a contenuto sanitario, la <Em>password.</Em> andrà rinnovata trimestralmente. Il mantenimento della segretezza dello <Em>user name </Em>e della <Em>password.</Em> è di esclusiva responsabilità dell’utente.
</Text>
<Text mt={4} textAlign="justify" pl={7}>
23. L’utente ha l’onere di custodire con la più elevata diligenza, commisurata alla natura sensibile dei 
contenuti gestiti tramite l’applicazione, le proprie credenziali di accesso che devono essere 
utilizzate esclusivamente dal genitore stesso o senza possibilità alcuna di essere cedute a terzi.
 L’utente si impegna a tenerle segrete, ad assicurarsi che nessun terzo vi abbia accesso e a 
 informare immediatamente <strong>AIHEALTH srl</strong> nel caso in cui sospetti o venga a conoscenza di un uso 
 indebito o di una non voluta divulgazione delle stesse. 
</Text>
         <Text mt={4} textAlign="justify" pl={7}>
         24. In caso di smarrimento o di compromissione, sarà assegnata all’utente una nuova <Em>password </Em> mediante un’opportuna procedura con adeguato livello di sicurezza. L’utente si impegna a: comunicare tempestivamente qualsiasi utilizzo non autorizzato della propria <Em>password </Em> o del proprio <Em>account</Em>, nonché qualsiasi altra violazione delle regole di sicurezza di cui venga a conoscenza; si impegna, inoltre, a uscire dal proprio <Em>account</Em> al termine di ogni sessione e a mantenere la disconnessione automatica di sicurezza . L’utente dovrà fornire le informazioni personali richieste dalla procedura di registrazione. Tali informazioni dovranno essere complete e corrispondenti al vero.
          Qualora <strong> AIHEALTH srl </strong> dovesse ritenere le informazioni fornite false o gravemente incomplete, essa si riserva di negare la registrazione all’utente che le ha fornite o di impedirne i successivi accessi.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         25. L’utente è il solo responsabile dei contenuti caricati e trasmessi tramite l’applicazione <strong>Kiddocare. AIHEALTH srl</strong> non garantisce la veridicità, la qualità, l’aggiornamento di tali contenuti. L’utente si impegna <strong><u>a non utilizzare</u> Kiddocare</strong> per: a) caricare o in qualsiasi modo trasmettere o diffondere contenuti illeciti, che possano arrecare danno a soggetti terzi, contenuti diffamatori, osceni o lesivi della privacy; b) arrecare danno a minori di età; c) falsificare la propria identità; d) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere un contenuto che non abbia il diritto di trasmettere o diffondere in forza di una previsione di legge, di contratto ovvero a causa di un rapporto fiduciario, per esempio informazioni riservate, informazioni confidenziali apprese in forza di un rapporto di lavoro o protette da un patto di riservatezza); e) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere un contenuto che comporti la violazione di brevetti, marchi, segreti, diritti di autore o altri diritti di proprietà industriale e/o intellettuale di terzi soggetti; f) caricare, pubblicare, inviare via e-mail o in altro modo trasmettere o diffondere pubblicità, materiale promozionale, “junk mail”, “spam” o qualsiasi altra forma di sollecitazione non autorizzata o non richiesta; g) caricare, inviare via e-mail o in altro modo trasmettere o diffondere virus informatici o altri codici, file o programmi creati per interrompere, distruggere o limitare il funzionamento di <Em>software</Em>, hardware o 
         impianti di telecomunicazione; h) raccogliere o archiviare dati personali degli altri utenti.
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         26. I dati saranno memorizzati in una banca dati ospitata da <Em>server</Em> che si trovano nel territorio europeo. Attraverso sistemi di <Em>backup </Em>giornaliero viene garantito che ogni dato memorizzato nel database non verrà perso o casualmente modificato.
         </Text>
   
         <Text mt={4} textAlign="justify" pl={7}>
         27. <strong>AIHEALTH srl </strong>si riserva il diritto di rifiutare o rimuovere qualsiasi contenuto immesso dagli utenti che costituisca violazione delle presenti condizioni generali di utilizzo o che risulti in altro modo reprensibile.
         </Text>
   
         <Text mt={4} textAlign="justify" pl={7}>
         28. L’utente si impegna a non riprodurre, duplicare, copiare, vendere, rivendere e comunque a non sfruttare a fini commerciali le funzionalità dell’applicazione.
         <br/>Costituiscono parte integrante del presente contratto gli Allegati come di seguito specificati</Text>
         <Text mt={4} textAlign="justify" pl={7}> 
          
         </Text>
         <Text mt={4} textAlign="justify" pl={7}> <Link to="../parents/Allegato1" >
         <u><strong>Allegato 1: </strong>  Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare.</u></Link>
         </Text>
         <Text mt={4} textAlign="justify" pl={7}> <Link to="../parents/Allegato2" >
         <u><strong>Allegato 2: </strong>Modulo per il recesso.</u></Link>
          </Text>
         <Text mt={4} textAlign="justify" pl={7}> <Link to="../parents/information-personal-data-child" >
         <u><strong>Allegato 3:  </strong> Informativa privacy relativa al trattamento dei dati personali del minore.</u></Link>
          </Text>
         <Text mt={4} textAlign="justify" pl={7}> <Link to="../parents/information-personal-data" >
         <u><strong>Allegato 4:  </strong> informativa privacy relativa al trattamento dei dati personali del genitore.</u></Link>
          </Text>
         <Text mt={4} textAlign="justify" pl={7}> <Link to="../parents/information-personal-data-bis" >
         <u><strong>Allegato 5:  </strong> Informativa privacy relativa al trattamento dei dati personali del genitore a fini di marketing.</u></Link>
          </Text>
        
         <Text mt={4} textAlign="justify" pl={7}>
         <strong>Dichiaro di avere letto con attenzione e di approvare specificamente le pattuizioni contenute:</strong>
         </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         <strong>- nei termini contrattuali: articoli da 1 a 20.</strong>
   
   </Text>
         <Text mt={4} textAlign="justify" pl={7}>
         <strong>- nelle condizioni generali di utilizzo: articoli da 21 a 28.</strong>
         </Text>
   
   
    
    </Container>
  );
}