import { Container, Heading, Text, Em } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export const Allegato1ped: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

         <Heading mt={'50'}> Allegato 1 
        
                 </Heading>
                 <Heading mt={'2'} mb={'20'}> Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare
                 </Heading>
           
                 <Text mt={4} textAlign="justify" pl={7}>  <strong>1.  Introduzione </strong> <br/>  Il presente documento fornisce una descrizione dettagliata delle funzionalità e dei servizi offerti dalla piattaforma Kiddocare per i pediatri. L'obiettivo di Kiddocare è agevolare la gestione delle richieste mediche da parte dei professionisti, garantendo un servizio efficiente e sicuro per i pazienti e i loro genitori.
                 </Text>
                 <Text mt={4} textAlign="justify" pl={7}> <strong>2. Registrazione e Accesso</strong> <br/> L’accesso alla piattaforma Kiddocare è subordinato alla registrazione dell’utente, che prevede l’accettazione dei termini e condizioni d’uso, nonché delle informative sulla privacy. Durante la registrazione, il pediatra è tenuto a compilare i propri dati professionali, essenziali per l’identificazione e l’abilitazione all’utilizzo del servizio.
                 </Text>
    
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 3. Home </strong> <br/>La schermata principale della piattaforma consente al pediatra di accedere rapidamente a tutte le funzionalità disponibili. Nella home è possibile visualizzare un riepilogo delle attività in corso, incluse richieste di consulto, certificati, prescrizioni e visite programmate.</Text>
        
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 4. Nuove Richieste   </strong> <br/>  Questa sezione raccoglie tutte le richieste inoltrate dai genitori dei pazienti. Le richieste sono suddivise in quattro categorie principali: richieste di SOS, consulti, prescrizioni e certificati. </Text>
              
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 5. Richieste di SOS (Funzione opzionale a scelta del pediatra)
                </strong> <br/>  Il pediatra può attivare o disattivare questa funzione per i propri pazienti. Prima dell’attivazione, il medico può definire una lista di casi disponibili per i pazienti, con la possibilità di modificarla in qualsiasi momento. È inoltre possibile disattivare la funzione sia globalmente che per utenti specifici.
                </Text>
            
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 6. Richieste consulti
                </strong> <br/> Il pediatra riceve le nuove richieste e visualizza il relativo numero nella home. Una volta aperte, le richieste assumono lo stato di "non evase" fino a quando il medico non intraprende un’azione, modificandone lo stato in "caso aperto" fino alla chiusura. Il consulto si considera concluso con la selezione di una motivazione predefinita.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 7. Richiesta prescrizioni
                </strong> <br/> Il pediatra può visualizzare le richieste di prescrizione e scegliere se accoglierle o respingerle. In caso di rigetto, è necessario specificarne la motivazione, che verrà comunicata al genitore. Se approvata, la prescrizione può essere emessa tramite SSN o in modalità bianca. Per le prescrizioni SSN, il sistema suddivide automaticamente le voci in prescrizioni separate e le invia all’utente.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 8. Richiesta certificati
                </strong> <br/>  Il pediatra può visualizzare e gestire le richieste di certificati. Se accoglie la richiesta, il certificato viene generato automaticamente con i dati precompilati, permettendo eventuali modifiche prima dell’invio. In caso di rigetto, la motivazione verrà comunicata al paziente. Se il certificato prevede un costo, prima dell’invio il pediatra può generare una richiesta di pagamento applicando, se necessario, uno sconto. </Text>
                
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 9. Calendario visite
                </strong>  Il pediatra definisce la disponibilità per le visite SSN e private, specificando se si svolgono da remoto, in studio o in modalità ibrida. Per i pazienti privati, è obbligatorio impostare almeno due tipologie di visita con relativi costi. Le visite da remoto possono essere effettuate tramite le piattaforme integrate in Kiddocare. Alla conclusione di una visita, in caso di pagamento, il pediatra seleziona le voci corrispondenti e invia la richiesta di pagamento al paziente.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 10. Ricerca paziente
                </strong> <br/>  Il pediatra può ricercare i pazienti assegnati e consultare lo storico clinico. È possibile inviare comunicazioni individuali ai genitori o selezionare gruppi di pazienti in base a determinate caratteristiche per monitoraggio e notifiche.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 11. Profilo </strong><br/>   Nella sezione profilo, l’utente può visualizzare e aggiornare i propri dati, nonché quelli dei figli. Sono disponibili impostazioni per la personalizzazione dell’app, oltre alla possibilità di effettuare il logout o richiedere la cancellazione dell’account.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 12. Studio Medico   </strong> <br/>  Questa sezione consente al pediatra di gestire e modificare i dettagli relativi al proprio studio, inclusi dati professionali e indirizzi.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 13. Pagamenti  </strong> <br/>  I pagamenti vengono elaborati tramite il modulo Stripe, garantendo sicurezza e anonimato. I dati di pagamento non sono visibili né al servizio né al paziente. Per ulteriori dettagli, consultare il documento "Termini e condizioni".
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 14. Privacy e Sicurezza  </strong> <br/>  Tutti i dati trattati dalla piattaforma sono protetti mediante crittografia end-to-end, assicurando il massimo livello di sicurezza e riservatezza.
                </Text>
            
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 15. Assistenza e Supporto
                </strong> <br/>  Il pediatra può accedere al servizio di supporto tramite email e chat WhatsApp, disponibile per fornire assistenza su problematiche tecniche e amministrative. Il supporto è accessibile direttamente dalla sezione dedicata nell’app.
                </Text>
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 16. Notifiche  </strong><br/>   L’app invia notifiche relative ai servizi e all’avanzamento delle richieste. L’utente riceverà notifiche solo previa accettazione dei relativi permessi.
                </Text>
                
                <Text mt={4} textAlign="justify" pl={7}>  <strong> 15. Conclusioni</strong> <br/>  Il presente documento fornisce un quadro esaustivo delle funzionalità offerte da Kiddocare per i pediatri. L’utilizzo della piattaforma implica la conoscenza e l’accettazione delle modalità operative qui descritte. Per eventuali aggiornamenti o modifiche, si rimanda alla documentazione ufficiale fornita dalla piattaforma. </Text>
                     </Container>
                   );
                 }
   
     