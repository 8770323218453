import { Container, Heading, Text, Em } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const PolicyParentPersonalDataPage: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

      <Heading mt={'50'}>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI </Heading>
      <Heading mt={'2'} mb={'20'}>in applicazione del Regolamento europeo n. 2016/679 e prestazione del consenso al trattamento </Heading>
      

      <Text mt={4} textAlign="justify">
       Egregio Signore, gentile Signora,</Text>
      <Text mt={4} textAlign="justify">
      nella Sua qualità di interessato, Le sottoponiamo la seguente informativa al fine del rilascio del Suo consenso al trattamento dei dati.
      L’informativa viene resa per adempiere agli obblighi imposti dal Regolamento europeo sulla protezione dei dati personali (Regolamento Europeo 2016/679).
      </Text>
      <Text mt={4} textAlign="justify">
      Il <strong>Titolare del trattamento</strong>, cioè chi assume le decisioni in merito alle modalità e alle finalità del trattamento, è il <strong>medico pediatra</strong> da Lei scelto per il minore e a cui può collegarsi mediante la piattaforma <strong>KIDDOCARE</strong>, fornita da <strong>AIHEALTH srl</strong> con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: amministrazione@ai-health.it, PEC: aihealth@pec.it.

        </Text>
      <Text mt={4} textAlign="justify">

      <strong>I Suoi dati personali vengono raccolti dal pediatra per la cura del minore mediante l’applicazione KIDDOCARE.</strong>
      <br/>Essi sono trattati con modalità informatico-telematiche al fine di fornirLe la possibilità di utilizzare l’applicazione <strong> KIDDOCARE</strong> nelle sue particolari funzioni, con le modalità specificate nelle <strong>Modalità e condizioni d’uso</strong> consultabili accedendo all’applicazione e al seguente link <Link to="../parents/Allegato1" >
      <strong> (Allegato 1) </strong> </Link> 
  
      <br/>I Suoi dati personali saranno trattati dal Titolare al fine di consentirLe l’utilizzo della applicazione, nell’ambito delle finalità sopra elencate, in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.

       </Text>
      <Text mt={4} textAlign="justify">
      Si precisa che, previo Suo consenso, il trattamento dei Suoi dati potrà essere effettuato anche mediante sistemi di Intelligenza Artificiale, sia al fine di supportare il pediatra sotto il profilo organizzativo, nonché - previa anonimizzazione degli stessi - a fini di ricerca scientifica e statistica.
      </Text>
      <Text mt={4} textAlign="justify"> Per sistema di Intelligenza Artificiale (IA) si intende un sistema automatizzato progettato per funzionare con livelli di autonomia variabili e che deduce dalle informazioni che riceve come generare previsioni, contenuti, raccomandazioni o decisioni.</Text>
      <Text mt={4} textAlign="justify"> Il trattamento dei dati personali per finalità di sviluppo di sistemi di IA prevede che tali dati siano utilizzati per l’addestramento del sistema e per la costruzione di algoritmi (modelli matematici che interpretano i dati).
      </Text>
      <Text mt={4} textAlign="justify"> Una delle funzionalità impiegate da <strong> KIDDOCARE</strong>, che potrebbe coinvolgere i suoi dati personali, riguarda la possibilità di migliorare l’organizzazione e l’efficienza delle prestazioni erogate dal pediatra, anche mediante l’interazione con l’agenda dei suoi appuntamenti e le giornate e le fasce orarie di reperibilità.
      </Text>
      <Text mt={4} textAlign="justify"> La piattaforma rende disponibili una serie di strumenti digitali diversificati che potranno essere di volta in volta attivati a seconda delle necessità a giudizio del medico curante.
      </Text>
      <Text mt={4} textAlign="justify">
      I dati saranno trattati con modalità informatico-telematiche e archiviati dal Titolare per le finalità sopra indicate e in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.
      <br/>Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
      <br/>Tali operazioni di trattamento dei dati personali avvengono rispettando scrupolosamente i principi di riservatezza e di sicurezza richiesti dalla legge sopra richiamata ed ispirandosi ai principi di correttezza e liceità di trattamento.
      </Text>
      <Text mt={4} textAlign="justify"> Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
      </Text>
      <Text mt={4} textAlign="justify">
       Il conferimento dei dati e il consenso al relativo trattamento sono facoltativi, ma rappresentano condizioni necessarie per l’utilizzo della applicazione e l’eventuale rifiuto potrà comportare l’impossibilità di utilizzare l’applicazione <strong>KIDDOCARE</strong>, nonché di accedere alla piattaforma collegata.
      </Text>

      <Text mt={4} textAlign="justify"> Con riguardo ai sistemi di intelligenza artificiale, la mancanza di alcuni dati (che costituiscono gli “esempi” o elementi di base per l’apprendimento del sistema) potrebbe portare ad una rappresentazione non accurata di alcune condizioni e/o patologie con conseguenze sull’efficienza e sull’accuratezza del sistema di IA o limiti nel suo utilizzo e/o nella sua funzionalità.
       </Text>
      <Text mt={4} textAlign="justify">
      Con riguardo alla durata del trattamento, i Suoi dati personali saranno conservati per il periodo di tempo in cui il minore sarà in cura presso il pediatra e inoltre per tutto il tempo richiesto per adempiere agli obblighi previsti dalla legge.
      <br/>Previo Suo consenso, le informazioni acquisite nel corso dell’utilizzo della piattaforma potranno essere trattate a fini di ricerca scientifica e di addestramento dei sistemi di intelligenza artificiale <u>ma solo in modalità anonima.</u>

         </Text>
         <Text mt={4} textAlign="justify">
         Per il corretto funzionamento della piattaforma, i dati da Lei conferiti saranno comunicati a soggetti autorizzati dal Titolare e responsabili, quali:
        <br/> - Autorizzati: collaboratori del medico curante e personale impiegatizio dello Studio;
        <br/> - Responsabili: <strong>AIHEALTH srl</strong> in quanto società fornitrice della piattaforma web e della applicazione <strong>KIDDOCARE,</strong> nonché i suoi fornitori e partner tecnologici;
        <br/> - gli amministratori di sistema e i fornitori dei servizi informatici, in qualità di responsabili del trattamento.
        <br/> Resta fermo l'obbligo del Titolare di comunicare i dati ad Autorità Pubbliche su specifica richiesta.

         </Text>
         <Text mt={4} textAlign="justify">
         La informiamo che lei ha diritto di: 
         <br/> - chiedere al Titolare l’accesso ai propri dati personali, la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati che la riguardato o di opporsi al loro trattamento, inoltre chiedere di trasmetterli a un altro Titolare del trattamento (portabilità dei dati);
         <br/> - revocare il consenso, in qualsiasi momento, laddove il trattamento dei suoi dati personali si basa sul consenso. Potrà revocare il Suo consenso inviando una comunicazione al Titolare. Si precisa che la mancanza del consenso al trattamento  comporterà l’impossibilità di utilizzare l’applicazione <strong>KIDDOCARE</strong>, nonché di accedere alla piattaforma collegata;
         <br/> - proporre un reclamo all’Autorità di controllo ovvero l’autorità per la protezione dei dati personali nazionale o di qualunque altro paese dell’UE.

         </Text>
        
         <Text mt={4} textAlign="justify"> I Suoi dati personali che saranno oggetto di trattamento per le finalità sopra riportate sono i seguenti:
         </Text>
         <Text mt={4} textAlign="justify"> <br/> - Nome,<br/> - Cognome, <br/> - Luogo di nascita, <br/> - Data di nascita, <br/> - Codice fiscale,<br/> - Indirizzo Email,<br/> - Indirizzo di residenza, <br/> - Indirizzo di domicilio, <br/> - Indirizzo fiscale <br/> - numero di Telefono, <br/>  - Password, <br/>  - Data e ora dei collegamenti con il medico curante,  
          <br/> - Foto Carta d’identità <br/> - Foto Firma
          <br/> - Messaggi audio e video
         
         </Text>
        
        

    </Container>
  );
}