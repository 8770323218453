
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text } from "@chakra-ui/react";

export const PolicyPrivacy: React.FC = () => {
  return (
    <Box textAlign="center" bg="gray.50" minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box bg="white" p={6} rounded="xl" shadow="lg" width="100%" maxW="500px">
        <Heading as="h1" size="lg" color="secondaryPediatrician.700" mb={6} >
          Policy e Privacy del Pediatra
        </Heading>
        <Box mb={5}>
                  <Link to="../pediatrician/Allegato1ped" style={{ textDecoration: "none", color: "secondaryPediatrician.200", fontWeight: "semibold" }}>
                    <Box
                      p={2}
                      borderWidth={2}
                      borderColor="primaryPediatrician.200"
                      rounded="md"
                      textAlign="center"
                      _hover={{ bg: "primaryPediatrician.300", color: "white" }}
                      _active={{ bg: "primaryPediatrician.200", color: "white" }}
                    >
                       Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare
                    </Box>
                  </Link>
                </Box>
                <Box mb={5}>
                  <Link to="../pediatrician/Allegato2ped" style={{ textDecoration: "none", color: "secondaryPediatrician.200", fontWeight: "semibold" }}>
                    <Box
                      p={2}
                      borderWidth={2}
                      borderColor="primaryPediatrician.200"
                      rounded="md"
                      textAlign="center"
                      _hover={{ bg: "primaryPediatrician.300", color: "white" }}
                      _active={{ bg: "primaryPediatrician.200", color: "white" }}
                    >
                       Modulo per il recesso
                    </Box>
                  </Link>
                </Box>
        <Box mb={4}>
          <Link to="../pediatrician/information-personal-data" style={{ textDecoration: "none", color: "secondaryPediatrician.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPediatrician.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPediatrician.300", color: "white" }}
              _active={{ bg: "primaryPediatrician.200", color: "white" }}
            >
               Informativa per il Trattamento dei Dati Personali

            </Box>
          </Link>
        </Box>
        <Box mb={4}>
          <Link to="../pediatrician/nomination-data" style={{ textDecoration: "none", color: "secondaryPediatrician.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPediatrician.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPediatrician.300", color: "white" }}
              _active={{ bg: "primaryPediatrician.200", color: "white" }}
            >  Nomina Responsabile
            </Box>
          </Link>
        </Box>
      
        <Box mb={4}>
          <Link to="../pediatrician/terms-conditions" style={{ textDecoration: "none", color: "secondaryPediatrician.200", fontWeight: "sembold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPediatrician.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPediatrician.300", color: "white" }}
              _active={{ bg: "primaryPediatrician.200", color: "white" }}
            >
              Termini e Condizioni Utente Pediatra
            </Box>
          </Link>
        </Box>
        <Text mt={4} fontSize="sm" color="gray.500">
          Seleziona una pagina per maggiori dettagli.
        </Text>
      </Box>
    </Box>
  );
};

export default PolicyPrivacy;
