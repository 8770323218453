import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/ui/button';
import { Carousel, CarouselImage } from '../../components/ui/carousel';

import { Box, Flex, Heading, Text, Badge, Container, Image, Grid, List, ListItem, Icon } from "@chakra-ui/react";

export const Home: React.FC = () => {

  return (
    <Box  position="relative" zIndex={0}>
      {/* Prima Sezione: Header */}
      <Flex
      direction={{ base: "column", md: "row" }}
      align="center"
      justify="space-between"
      p="2rem"
      zIndex={2} 
>
  <Box
    flex="1"
    mr={{ md: "1rem" }}
    color="primaryPatient.700"
    textAlign={{ base: "center", md: "left" }}
  >
    {/* Scritta iniziale molto grande */}
    <Heading as="h1" size="4xl" mb="1rem">
    La salute dei bambini è diventata <br/>
     più semplice
    </Heading>
    
    {/* Sottotitolo */}
    <Heading as="h2" size="lg" mb="0.5rem">
    Benvenuto su Kiddocare
    </Heading>
    
    <Text mb="2rem">
    Pensato per il settore pediatrico, Kiddocare utilizza l’intelligenza artificiale per migliorare <br />
     l'interazione tra medico e paziente, offrendo soluzioni personalizzate in grado  <br/>
     di soddisfare le esigenze di entrambi.
    </Text>

    {/* Bottone con colore verde */}
   
    <Box >
            <Button variant={'surface'} colorPalette={'primaryPatient'} margin={'0'} asChild>
            <Link to={'#'}> Scarica l'app </Link>
            </Button>
            </Box>
    </Box>

    <Image
      src="/images/royaltyfree/benvenuti.png"
      alt="Immagine decorativa in alto a destra"
      position="absolute"
      top="0"
      right="0"
      maxW="550px"
      pb="10rem"
      zIndex={-1}
      display={{ base: 'none', md: 'none', lg: 'block' }} 
  />
  
</Flex>
      {/* Seconda Sezione: Galleria di immagini */}
      <Flex 
        justify="space-around" 
        p="2rem" 
        wrap="wrap" 
        gap="1rem" 
        bg="primaryPatient.50" 
        
        
        direction={{ base: "column", md: "row" }}
      >
        <Box textAlign="center" w={{ base: "100%", md: "30%" }}>
          <Image
            src='/images/royaltyfree/mammabambina.jpg'
            alt="Gallery 1"
            w="100%"
            transition="transform 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          />
          <Text fontSize="lg" fontWeight="bold" color= "secondaryPatient.700"  mt="1rem">La nostra Vision</Text>
          <Text fontSize="sm"> Consentire ai pediatri di ottimizzare il loro tempo e offrire ai genitori strumenti semplici per monitorare la salute dei loro figli, grazie a soluzioni tecnologiche avanzate.</Text>
        </Box>

        <Box textAlign="center" w={{ base: "100%", md: "30%" }}>
          <Image
            src='/images/royaltyfree/mammacomputer.jpg'
            alt="Gallery 2"
            w="100%"
            transition="transform 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          />
          <Text fontSize="lg" fontWeight="bold" color= "secondaryPatient.700" mt="1rem">La nostra Mission</Text>
          <Text fontSize="sm">Innovare il settore pediatrico, semplificando la relazione tra medico e paziente grazie alle nuove tecnologie, per rendere l'assistenza sanitaria più fluida ed efficace.</Text>
        </Box>

        <Box textAlign="center" w={{ base: "100%", md: "30%" }}>
          <Image
            src='/images/royaltyfree/montagne.jpg'
            alt="Gallery 3"
            w="100%"
            transition="transform 0.3s"
            _hover={{ transform: "scale(1.1)" }}
          />
          <Text fontSize="lg" fontWeight="bold" color= "secondaryPatient.700" mt="1rem"> Il nostro background</Text>
          <Text fontSize="sm"> Nata dall'esigenza di un pediatra, con decenni di esperienza, di velocizzare la comunicazione attraverso un canale online dedicato.</Text>
        </Box>
        </Flex>

    
    <Box as="section" bg="gray.50" p="4rem">
      <Flex
        
        direction={{ base: "column", md: "row" }}
        align="stretch" /* Allinea le colonne per altezza */
        justify="space-between" /* Spaziatura uniforme tra le colonne */
        gap="4rem" /* Aggiunge spazio tra le colonne */
        
      >
        {/* Prima sezione */}
        <Box
          flex="1"
          textAlign="center"
          p="2rem"
         
       
        >
          <Heading
            as="h2"
            size="2xl"
            color="primaryPatient.700"
            mb="1rem"
            _hover={{ transform: "scale(1.1)", transition: "0.3s" }}
          >
            Sei un pediatra?
          </Heading>
          <Text fontSize="lg" color="primaryPatient.500" mb="4">
            
            Vuoi conoscere di più sulla nostra applicazione?
             </Text>
            <Box >
            <Button variant={'surface'} colorPalette={'primaryPatient'} margin={'5'} asChild>
            <Link to={'mailto:info@kiddocare.it'}>Contattaci</Link>
            </Button>
            </Box>


          
        </Box>

        {/* Seconda sezione */}
        <Box flex="1" textAlign="center" p="2rem">
          <Heading
            as="h2"
            size="2xl"
            color="primaryPatient.700"
            mb="1rem"
            _hover={{ transform: "scale(1.1)", transition: "0.3s" }}
            
          >
            Sei un genitore?
          </Heading>
          <Text fontSize="lg" color="primaryPatient.500">
            Il tuo pediatra ancora non la conosce? <br/> Parlagliene!
          </Text>
        </Box>
      </Flex>
    </Box>




      {/* Quinta Sezione: Divisione in due colonne */}
      <Flex
  direction={{ base: "column", md: "row" }}
  align="center"
  justify="space-between"
  px={ {base:"3rem", md: "3rem" , lg: "3rem"}}
  pt= { {base:"3rem", md: "3rem" , lg: "3rem"}}
  bg="primaryPatient.50"
>
  {/* Colonna di sinistra: Testo con elenco dei vantaggi */}
  <Box flex="1" mr={{ md: "1rem" }} textAlign={{ base: "center", md: "left" }}>
    <Heading size="lg" fontWeight="bold" color="primaryPatient.600" mb="1.5rem">
    L'abbonamento ti dà accesso alla nostra app, dove puoi:
    </Heading>
    {/* Vantaggi elencati manualmente */}
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="secondaryPatient.700">
        ✔
      </Text>
      <Text fontSize="md">
        Consulto rapido con il pediatra: chat, telefonata, videochiamata
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
        Ricette, prescrizioni e certificati richiesti e scaricati direttamente.
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
       Prenotazione visite, convenzionate o private, da remoto o in studio
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
        Accesso allo storico di tuo figlio
      </Text>
    </Flex>
    <Flex align="center" mb="1rem">
      <Text fontSize="md" mr="0.5rem" color="blue.500">
        ✔
      </Text>
      <Text fontSize="md">
       Tasto SOS, Emergenze
      </Text>
    </Flex>
  </Box>

  {/* Colonna di destra: Immagine */}
  <Image
    src='/images/drawing/mano_home.png'
    alt="App Screenshot"
    maxW= { {base:"130%", md: "60%" , lg: "60%"}}
    h="auto"
   
   
    
  />
</Flex>
      
    </Box>
  );
};

export default Home;
