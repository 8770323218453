import { Container, Heading, Text, Em } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export const Allegato1: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>
   
         <Heading mt={'50'}> Allegato 1 

         </Heading>
         <Heading mt={'2'} mb={'20'}> Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare
         </Heading>
   
         <Text mt={4} textAlign="justify" pl={7}>  <strong>1.  Introduzione </strong> <br/> Kiddocare è un'applicazione dedicata ai genitori per la gestione della salute dei propri figli in collaborazione con il pediatra di riferimento. Attraverso un'interfaccia intuitiva e sicura, Kiddocare offre un'ampia gamma di funzionalità, tra cui la richiesta di consulti, certificati, prescrizioni, gestione degli appuntamenti e monitoraggio dello storico clinico. Il presente documento descrive in dettaglio le funzionalità e i servizi offerti dall'applicazione.
        </Text>
         <Text mt={4} textAlign="justify" pl={7}> <strong>2. Registrazione e Accesso</strong> Per utilizzare Kiddocare, l'utente deve completare i seguenti passaggi:
        </Text>
         <Text mt={4} textAlign="justify" pl={9}> <strong> Accettazione delle condizioni d'uso e delle informative sulla privacy:</strong> Durante la fase di registrazione, l'utente deve leggere e accettare i termini e condizioni d'uso, nonché le informative relative al trattamento dei dati personali.
         L'utente fornisce informazioni quali nome, cognome, data di nascita e contatti necessari per l'identificazione e la gestione del profilo.
         </Text>
        <Text mt={4} textAlign="justify" pl={9}> <strong>Registrazione dei figli:</strong> Per ogni figlio da monitorare, l'utente deve inserire i dati anagrafici, inclusi nome, data di nascita e informazioni sanitarie pertinenti.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}> <strong>Associazione al pediatra di riferimento:</strong>L'utente seleziona il pediatra che seguirà il bambino all'interno della piattaforma, garantendo un collegamento diretto tra medico e paziente.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 3. Home </strong> La schermata principale dell'app offre una panoramica completa delle funzionalità disponibili. <br/> Da qui, l'utente può:
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Accedere rapidamente a tutte le funzionalità principali, </strong> tra cui richieste di consulto, prescrizioni, appuntamenti e certificati.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Associare un pediatra privato a ciascun figlio,  </strong>  permettendo la gestione separata per ogni bambino.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong>Selezionare il pediatra di competenza per ogni azione successiva,  </strong>  in modo da personalizzare l'esperienza in base al professionista di riferimento.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Accedere alla sezione Community Kiddocare, </strong>  dove saranno disponibili articoli della comunità scientifica e dei pediatri associati a Kiddocare.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 4. Richiesta Consulto   </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Descrizione dettagliata del problema: </strong> L'utente fornisce una spiegazione chiara dei sintomi riscontrati dal bambino, includendo dettagli rilevanti.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Compilazione di un questionario medico:</strong> L'app guida l'utente attraverso una serie di domande strutturate per raccogliere informazioni utili al pediatra.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Trasmissione delle informazioni al pediatra:</strong>  I dati raccolti vengono inviati automaticamente al pediatra per la valutazione. Ogni richiesta è accompagnata da un grado di urgenza, consentendo al medico di gestire le priorità in modo efficace.</Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Attivazione della chat con il medico: </strong> Una volta ricevuta la richiesta, il pediatra può avviare una conversazione diretta con il genitore per approfondire il problema.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 5. Richiesta Certificato
        </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Selezione del certificato necessario:</strong>  L'utente può scegliere tra un elenco predefinito di certificati disponibili.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Distinzione tra certificati gratuiti e a pagamento: </strong>  Alcuni certificati possono essere richiesti senza costi aggiuntivi, mentre altri richiedono un pagamento. </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Valutazione del pediatra:</strong>  Il medico esamina la richiesta e decide se approvare l'emissione del certificato o rifiutarlo, motivando la decisione.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Accesso al certificato:</strong>  Se il certificato è approvato, l'utente può visualizzarlo, scaricarlo, stamparlo o condividerlo. Nel caso di certificati a pagamento, il documento sarà disponibile solo dopo l'elaborazione del pagamento.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 6. Richiesta Prescrizioni </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Invio della richiesta di prescrizione: </strong> L'utente redige un breve messaggio descrivendo la necessità di una prescrizione medica. </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Possibilità di allegare documenti: </strong> L'utente può caricare file aggiuntivi (es. referti medici) per supportare la richiesta.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Valutazione da parte del pediatra:</strong>  Il medico analizza la richiesta e può emettere una o più prescrizioni oppure rifiutarla, specificando la motivazione.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Accesso e gestione della prescrizione: </strong> Una volta approvata, l'utente potrà visualizzare immediatamente i codici della prescrizione, nonché scaricare, condividere o stampare il documento.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 7. Appuntamenti e Telemedicina    </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong>  Prenotazione di visite mediche:  </strong>  L'utente può fissare un appuntamento con il pediatra, scegliendo tra una visita in studio o una consulenza a distanza.        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong>  Utilizzo della telemedicina:  </strong> Nel caso di visite da remoto, l'utente può avviare la consultazione direttamente dall'app.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong>  Gestione delle visite a pagamento:  </strong>  Per le prestazioni private, il pediatra emette una fattura e il pagamento deve essere effettuato prima della visita.</Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 8. SOS </strong> (Funzione opzionale a scelta del pediatra) </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Richiesta di intervento rapido: </strong>  L'utente può segnalare un problema urgente selezionando una casistica predefinita.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Attivazione personalizzata del servizio:  </strong>  Il pediatra può decidere se rendere disponibile questa funzionalità ai propri pazienti.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Importante avvertenza:  </strong> Questo servizio non sostituisce i numeri di emergenza nazionali come il 118 o la guardia medica.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 9. Storico Clinico </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Visualizzazione cronologica degli eventi medici:</strong> L'utente può consultare un calendario che raccoglie tutte le visite, prescrizioni e certificati emessi per il bambino.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 10. Profilo     </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Gestione dei dati personali:  </strong> L'utente può visualizzare, aggiornare o completare le informazioni anagrafiche proprie e dei figli.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Modifica delle impostazioni dell'app:  </strong> L'utente può configurare preferenze personalizzate, eseguire il logout o richiedere la cancellazione del profilo</Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 11. Notifiche   </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Gestione delle notifiche: </strong> L'app invia notifiche di carattere generale relative al servizio o specifiche sullo stato di avanzamento delle richieste. L'utente riceverà notifiche solo se ha accettato i permessi di notifica. </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 12. Pagamenti     </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Sicurezza e anonimato: </strong> Tutte le transazioni avvengono tramite il sistema di pagamento Stripe, che garantisce elevati standard di sicurezza.        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Riservatezza dei dati finanziari: </strong>  Le informazioni di pagamento non sono visibili né alla piattaforma Kiddocare né al pediatra.   </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 13. Privacy e Sicurezza  </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Protezione avanzata dei dati: </strong> Tutte le informazioni gestite dalla piattaforma sono criptate con tecnologia end-to-end.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Rispetto delle normative vigenti:</strong>  Kiddocare garantisce la conformità agli standard di protezione dei dati personali.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 14. Assistenza e Supporto
        </strong> </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Contatto diretto:  </strong> È disponibile un servizio di supporto via email e tramite chat WhatsApp per fornire assistenza su problematiche tecniche o amministrative. L'utente può accedere al servizio direttamente dalla sezione dedicata nel profilo.
        </Text>
        <Text mt={4} textAlign="justify" pl={9}>  <strong> Assistenza pediatrica:  </strong> Le richieste di natura medica devono essere indirizzate al pediatra di riferimento tramite le funzionalità di consulto e chat.
        </Text>
        <Text mt={4} textAlign="justify" pl={7}>  <strong> 15. Conclusioni</strong> <br/> Kiddocare offre un ambiente digitale sicuro ed efficiente per la gestione della salute pediatrica, facilitando la comunicazione tra genitori e pediatri e garantendo un accesso immediato e protetto ai servizi sanitari essenziali.
        </Text>
             </Container>
           );
         }