
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, Text } from "@chakra-ui/react";

export const PolicyPrivacyParent: React.FC = () => {
  return (
    <Box textAlign="center" bg="gray.50" minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box bg="white" p={4} rounded="xl" shadow="lg" width="100%" maxW="500px">
        <Heading as="h1" size="lg" color="secondaryPatient.700" mb={6} >
          Policy e Privacy del Genitore e Paziente
        </Heading>
        <Box mb={5}>
          <Link to="../parents/Allegato1" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >
               Descrizione dettagliata delle funzionalità e dei servizi erogati da Kiddocare
            </Box>
          </Link>
        </Box>
        <Box mb={5}>
          <Link to="../parents/Allegato2" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >
               Modulo per il recesso
            </Box>
          </Link>
        </Box>
        <Box mb={5}>
          <Link to="../parents/information-personal-data-child" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >
               Informativa per il Trattamento dei Dati Personali del minore

            </Box>
          </Link>
        </Box>
        <Box mb={5}>
          <Link to="../parents/information-personal-data" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "semibold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >   Informativa per il Trattamento dei Dati Personali del genitore
            </Box>
          </Link>
        </Box>
        <Box mb={5}>
          <Link to="../parents/information-personal-data-bis" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "sembold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >
               Informativa Privacy a fini di Marketing
            </Box>
          </Link>
        </Box>
        <Box mb={5}>
          <Link to="../parents/terms-conditions" style={{ textDecoration: "none", color: "secondaryPatient.200", fontWeight: "sembold" }}>
            <Box
              p={2}
              borderWidth={2}
              borderColor="primaryPatient.200"
              rounded="md"
              textAlign="center"
              _hover={{ bg: "primaryPatient.300", color: "white" }}
              _active={{ bg: "primaryPatient.200", color: "white" }}
            >
              Termini e Condizioni Utente
            </Box>
          </Link>
        </Box>
        <Text mt={4} fontSize="sm" color="gray.500">
          Seleziona una pagina per maggiori dettagli.
        </Text>
      </Box>
    </Box>
  );
};

export default PolicyPrivacyParent;
