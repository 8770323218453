import { Container, Heading, Text, Em } from '@chakra-ui/react';
import React from 'react';

export const Allegato2: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>
   
         
   
         <Text mt={4} textAlign="right">
         Fac-simile lettera di recesso (da inviarsi a mezzo <Em>tramite PEC o raccomandata postale)</Em>
            </Text>
            <Text mt={4} textAlign="right">
            Spettabile AIHEALTH S.r.l.
            <br/>Via Santa Sofia, 24
            <br/>20122 – Milano

            </Text>
         
         <Heading mt={'2'} mb={'20'}> OGGETTO: recesso dal contratto di licenza d’uso del __ /__ /____ (data acquisto)
         </Heading>

         <Text mt={4} textAlign="justify">
         Io sottoscritto/a <br/>
         Nome_______________________ Cognome ________________________
         <br/> indirizzo e-mail: ___________________________
         <br/>con l'invio della presente lettera, comunico il recesso dal contratto in oggetto concluso in data gg mm aaaa (data acquisto).</Text>
   
         <Text mt={4} textAlign="justify">
         Chiedo, altresì, che mi venga rimborsato entro e non oltre 14 giorni dal ricevimento della presente, l’importo da me versato, pari ad Euro _____________________ mediante riaccredito su carta di credito.
        </Text>
       
         <Text mt={4} textAlign="justify"> Data ______________ </Text>
         <Text mt={4} textAlign="right"> Firma <br/>
         _________________________ </Text>

         <Text mt={4} textAlign="justify"> Si allega: fotocopia del documento d’identità
         </Text>


            
             
             </Container>
           );
         }