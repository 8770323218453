import { Container, Heading, Text, Em } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const PolicyParentToChildPersonalDataPage: React.FC = () => {
  return (
    <Container alignItems={'center'} textAlign={'center'} py={'20'}>

      <Heading mt={'50'}>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI </Heading>
      <Heading mt={'2'} >in applicazione del Regolamento europeo n. 2016/679 e prestazione del consenso al trattamento </Heading>
      
      <Heading mt={'20'} mb={'20'}>CONSENSO DEL GENITORE  (O ALTRO ESERCENTE LA POTESTA’ GENITORIALE) <br/>
      AL TRATTAMENTO <u>DEI DATI DEL MINORE IN CURA</u> </Heading>

       <Text mb={4} textAlign="justify">
              Egregio Signore, gentile Signora,<br/>
                    
        con la presente informativa viene richiesto il Suo consenso al <strong>trattamento dei dati personali, sensibili e sanitari del minore in cura presso il pediatra</strong> mediante la piattaforma web e l’applicazione <strong>KIDDOCARE</strong>, in quanto <strong>il consenso può essere validamente manifestato in caso di minore età da chi esercita la potestà genitoriale.</strong>
        L’informativa viene resa per adempiere agli obblighi imposti dal Regolamento europeo sulla protezione dei dati personali (Regolamento Europeo 2016/679).
        <br/> Si precisa che, previo Suo consenso, il trattamento dei dati afferenti al minore potrà essere effettuato anche mediante sistemi di Intelligenza Artificiale, sia al fine di supportare la prestazione sanitaria, sia - previa anonimizzazione degli stessi - a fini di ricerca e statistica.
        Per sistema di Intelligenza Artificiale (IA) si intende un sistema automatizzato progettato per funzionare con livelli di autonomia variabili e che deduce dalle informazioni che riceve come generare previsioni, contenuti, raccomandazioni o decisioni.
</Text>
       <Text mb={4} textAlign="justify">
              Il <strong>Titolare del trattamento</strong>, cioè chi assume le decisioni in merito alle modalità e alle finalità del trattamento, è il medico pediatra da Lei scelto per la cura del minore e a cui si collega anche mediante la piattaforma web e l’applicazione <strong>KIDDOCARE</strong>, fornite da <strong>AIHEALTH srl </strong> con sede in Milano, via Santa Sofia n. 24, P.IVA 13640270966, nella persona del suo legale rappresentante <Em>pro tempore</Em> Di Trana Michele, tel. 3403857266, indirizzo e-mail: amministrazione@ai-health.it, PEC: aihealth@pec.it.

              </Text>
       <Text mb={4} textAlign="justify">
       I dati personali, sensibili e sanitari del minore vengono raccolti specificamente per la cura e l’assistenza dello stesso da parte del pediatra da Lei scelto e mediante l’applicazione <strong>KIDDOCARE</strong>.

              </Text>
     <Text mb={4} textAlign="justify"> Essi sono trattati con tecnologie informatico-telematiche al fine di fornirLe la possibilità di utilizzare l’applicazione <strong>KIDDOCARE</strong> nelle sue particolari funzioni, con le modalità specificate nelle <strong>Modalità e condizioni d’uso</strong> consultabili accedendo all’applicazione e anche al seguente link
       <Link to="../parents/Allegato1" >
      <strong> (Allegato 1) </strong></Link>
      <br/>Il trattamento dei dati personali, sensibili e sanitari del minore per finalità di sviluppo di sistemi di IA prevede che tali dati siano utilizzati per l’addestramento del sistema e per la costruzione di algoritmi (modelli matematici che interpretano i dati).
      <br/>Tali sistemi possono essere utilizzati per agevolare la diagnosi (prevenzione, classificazione e stratificazione delle condizioni patologiche dei pazienti), la comprensione di meccanismi fisiopatologici (comprendere perché e come i pazienti sviluppino determinate malattie), nel considerare quale trattamento di cura potrà essere più appropriato e nel cercare di prevedere la risposta al trattamento e l’evoluzione delle patologie.
      <br/>Inoltre, sotto il profilo della ricerca scientifica, la raccolta ed analisi di grandi quantità di dati sanitari permetterà lo sviluppo di modelli predittivi personalizzati, che aiuteranno ad individuare precocemente i pazienti a rischio di potenziali complicanze.
      <br/>Un’altra applicazione riguarda la possibilità di migliorare l’organizzazione e l’efficienza delle prestazioni erogate dal pediatra.

             </Text>

      <Text mb={4} textAlign="justify"> <strong> Nell’impossibilità tecnica di verificare il consenso dell’altro genitore all’utilizzo della applicazione KIDDOCARE Le raccomandiamo di accettare la presente informativa e le allegate condizioni d’uso SOLO QUALORA vi sia anche il consenso dell’altro esercente la potestà genitoriale.
      </strong>
      
             </Text>

      <Text mb={4} textAlign="justify"> I dati personali del minore saranno trattati e archiviati dal Titolare per la cura e l’assistenza anche attraverso la piattaforma web e l’applicazione <strong>KIDDOCARE,</strong> in conformità alle modalità di trattamento previste dal Regolamento europeo sulla privacy.
             </Text>

      <Text mb={4} textAlign="justify"> La piattaforma rende disponibili una serie di strumenti digitali diversificati che potranno essere di volta in volta attivati dal curante a seconda delle necessità del minore curato.
             </Text>
      <Text mb={4} textAlign="justify"> Specifiche misure di sicurezza sono adottate dal Titolare per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
    </Text>

      <Text mb={4} textAlign="justify"> Il conferimento dei dati e il consenso al relativo trattamento sono facoltativi, ma rappresentano condizioni necessarie per l’utilizzo dell’applicazione e l’eventuale rifiuto potrà comportare l’impossibilità di utilizzare l’applicazione <strong>KIDDOCARE</strong>,  nonché di accedere alla piattaforma collegata.
      <br/> Con riguardo ai sistemi di intelligenza artificiale, la mancanza di alcuni dati (che costituiscono gli “esempi” o elementi di base per l’apprendimento del sistema) potrebbe portare ad una rappresentazione non accurata di alcune condizioni e/o patologie con conseguenze sull’efficienza e sull’accuratezza del sistema di IA o limiti nel suo utilizzo e/o nella sua funzionalità.
        
             </Text>

             <Text mb={4} textAlign="justify">Le operazioni di trattamento dei dati personali avvengono rispettando scrupolosamente i principi di riservatezza e di sicurezza richiesti dalla legge sopra richiamata ed ispirandosi ai principi di correttezza e liceità di trattamento.
</Text>
             <Text mb={4} textAlign="justify"> I dati personali, sensibili e sanitari saranno conservati per il periodo in cui il minore sarà in cura presso il pediatra e inoltre per il tempo necessario alla esecuzione degli obblighi previsti dalla legge.
              <br/> Previo Suo consenso, le informazioni acquisite nel corso dell’utilizzo della piattaforma potranno essere trattate a fini di ricerca scientifica e di addestramento dei sistemi di intelligenza artificiale ma solo in <u>modalità anonima.</u>

             </Text>
             <Text mb={4} textAlign="justify"> 
             Per il corretto funzionamento della piattaforma, i dati da Lei conferiti saranno comunicati a soggetti autorizzati dal Titolare e responsabili, quali:
             <br/> - Autorizzati: collaboratori del medico curante e personale impiegatizio dello Studio;
             <br/> - Responsabili: <strong>AIHEALTH srl</strong> in quanto società fornitrice della piattaforma web e della applicazione <strong>KIDDOCARE</strong>, nonché i suoi fornitori e partner tecnologici;
             <br/> - gli amministratori di sistema e i fornitori dei servizi informatici, in qualità di responsabili del trattamento.
             <br/> Resta fermo l'obbligo del Titolare di comunicare i dati ad Autorità Pubbliche su specifica richiesta.

             </Text>

             <Text mb={4} textAlign="justify"> La informiamo che lei, in qualità di esercente la potestà genitoriale sull’assistito, ha diritto di:

             <br/> - chiedere al Titolare l’accesso ai dati personali del minore, la rettifica o la cancellazione degli stessi o la limitazione del trattamento dei dati che lo riguardato o di opporsi al loro trattamento, inoltre chiedere di trasmetterli a un altro Titolare del trattamento (portabilità dei dati);
             <br/> - revocare il consenso, in qualsiasi momento, laddove il trattamento dei dati personali si basi sul consenso. Potrà revocare il Suo consenso inviando una comunicazione al Titolare. Si precisa che la mancanza del consenso al trattamento comporterà l’impossibilità di utilizzare l’applicazione <strong>KIDDOCARE</strong> nonché di accedere alla piattaforma collegata;
             <br/> - proporre un reclamo all’Autorità di controllo ovvero l’autorità per la protezione dei dati personali nazionale o di qualunque altro paese dell’UE.

             </Text>
             <Text mb={4} textAlign="justify">
             I dati del paziente che saranno oggetto di trattamento per le finalità sopra riportate sono i seguenti:

             
                       <br/> - Nome
                       <br/> - Cognome
                       <br/> - Luogo di nascita
                       <br/> - Data di nascita
                       <br/> - Codice Fiscale
                       <br/> - ASL di riferimento
                       <br/> - Indirizzo di residenza
                       <br/> - Indirizzo di domicilio
                       <br/> - Indirizzo fiscale
                       <br/> - Tessera Sanitaria
                       <br/> - Foto
                       <br/> - Dati afferenti alla salute

                       
                     </Text>
            
      
    </Container>
  );
}