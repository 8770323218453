import { Container, Heading, Text, Em} from '@chakra-ui/react';
import React from 'react';

export const PolicyPediatricianNominationPage : React.FC = () => {
  return (
    

      <Container maxW="container.lg" py={20}>
            {/* Titoli principali */}
            <Heading as="h1" textAlign="center" >
            Atto di nomina a Responsabile esterno

            </Heading>
            <Heading as="h2" textAlign="center" mb={10}>
            del trattamento di dati personali

            </Heading>
      

      <Text mt={4} textAlign="justify">
        <strong> Oggetto: <span style={{ fontStyle: 'italic' }}></span> Oggetto: Nomina a responsabile del trattamento dei Dati Personali connesso all’erogazione dei servizi informatico-telematici della piattaforma Kiddocare a favore del Dott./della Dott.ssa (NOME E COGNOME DEL TITOLARE), in qualità di Titolare del trattamento dei dati personali, sensibili e a contenuto sanitario, ai sensi della vigente normativa sulla protezione dei dati personali (art. 28 del Regolamento 2016/679/UE - nel seguito anche “GDPR”)
        </strong>

      </Text>
      <Text mt={4} textAlign="justify">
      Il <strong>Dott./la Dott.ssa</strong>(NOME E COGNOME DEL TITOLARE), nella Sua qualità di Titolare del trattamento,

      </Text>
      <Text mt={4} textAlign="center">
        PREMESSO CHE
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      a) è in corso un rapporto contrattuale tra (NOME E COGNOME DEL TITOLARE) e <strong>AIHEALTH srl</strong>, finalizzato all’erogazione, in favore del Titolare dei servizi connessi all’impiego della piattaforma <strong> KIDDOCARE</strong>, <u>sistema informatico-telematico che consente l’efficace comunicazione tra il pediatra e il genitore (o altro esercente potestà genitoriale) per la cura e l’assistenza del minore;</u>
      <br/></Text>
      <Text mt={4} textAlign="justify" pl={7}>
      b) ai sensi della vigente normativa europea ed italiana in materia di protezione dei dati personali, la fruizione di tali servizi da parte del Titolare comporta, da parte di <strong>AIHEALTH srl</strong>, il trattamento di dati personali, sensibili e a contenuto sanitario  per conto del Titolare stesso;
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      c) a mezzo del presente atto, il Titolare intende disciplinare il trattamento dei dati personali, sensibili e a contenuto sanitario effettuato da <strong>AIHEALTH srl</strong> quale Responsabile del trattamento nell’esecuzione dei Servizi di cui al Contratto, ai sensi della normativa sulla protezione dei dati personali.
      </Text>
      <Text mt={4} textAlign="center">
      Tutto ciò premesso, tra le Parti si conviene e stipula quanto segue:
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      1. Con riferimento alle attività di trattamento dei dati personali, sensibili e sanitari connesse alla fornitura dei servizi di cui al Contratto, si stabilisce che tali attività sono svolte da <strong>AIHEALTH srl</strong>  per conto del Titolare del trattamento e che il <strong>AIHEALTH srl</strong>  agisce in qualità di Responsabile di tale trattamento, ex art. 28 del GDPR.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      2. Le Parti si danno reciprocamente atto che la fornitura dei servizi comporta il trattamento dei dati personali, sensibili e sanitari come contrattualmente convenuto e, in particolare, indicato nel Contratto e nei conseguenti Termini e condizioni d’uso, nonché come descritto nel seguito.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      3. <strong>AIHEALTH srl</strong>, in qualità di Responsabile, conferma di presentare garanzie sufficienti per mettere in atto misure tecniche e organizzative adeguate in modo tale che il trattamento risponda ai requisiti prescritti al fine di garantire la tutela dei dati personali e i diritti degli interessati.

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={7}>
      4. <strong>AIHEALTH srl</strong>  si impegna a rispettare gli obblighi che le disposizioni del GDPR pongono direttamente a carico del Responsabile del trattamento. In particolare:

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      a)  effettuare le operazioni di trattamento dei suddetti dati personali, sensibili e sanitari nel pieno rispetto dei principi e delle disposizioni della vigente normativa sulla protezione dei dati personali ed esclusivamente ai fini dell’esecuzione dei servizi di cui al Contratto, secondo le modalità, procedure e modulistiche via via indicate dal Titolare;


      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      b)  trattare i dati personali soltanto sulla base delle istruzioni fornite dal Titolare;


      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      c)  assistere il Titolare nel garantire il rispetto, per quanto di relativa competenza, degli obblighi in tema di sicurezza, notifica all’Autorità per la protezione dei dati personali di eventuali violazioni di dati personali e, se del caso, loro comunicazione agli interessati, nonché di valutazione d’impatto sulla protezione dati ed eventuale consultazione preventiva, ai sensi degli articoli da 32 a 36 del GDPR, tenendo conto della natura del trattamento e delle informazioni a disposizione dello stesso Responsabile, nonché delle documentate istruzioni via via impartite dal Titolare in relazione all’adempimento dei suddetti obblighi;


      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      d)  individuare le persone autorizzate al trattamento dei dati personali, che operano sotto l’autorità di <strong>AIHEALTH srl</strong>, nonché adottare le misure volte a (i) garantire l’assunzione da parte di tali persone di idonei obblighi di riservatezza in ordine ai dati personali trattati, (ii) fornire loro adeguate e documentate istruzioni circa il rispetto, in particolare, delle misure per la sicurezza dei dati e (iii) vigilare sulla osservanza, da parte delle persone autorizzate, delle istruzioni impartite per il trattamento dei dati personali e delle vigenti disposizioni normative in materia di protezione dei dati personali;
      <br/></Text>

      <Text mt={4} textAlign="justify" pl={9}>
      e) assicurare, ai fini della corretta applicazione della vigente normativa sulla privacy, il costante monitoraggio degli adempimenti e delle attività effettuati da chi opera sotto la propria autorità  in relazione alle operazioni di trattamento di competenza;

      <br/></Text>

      <Text mt={4} textAlign="justify" pl={9}>
      f)  informare periodicamente il Titolare, su richiesta di quest’ultimo, in ordine all’attività svolta, sia sotto il profilo del trattamento, sia sotto il profilo della sicurezza dei dati;

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      g) conservare i dati in una forma che consenta l’identificazione degli interessati per un periodo di tempo non superiore a quello necessario agli scopi per i quali sono stati raccolti e successivamente trattati;

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      h)  inviare al Titolare previa apposita richiesta scritta, al momento della cessazione delle operazioni di trattamento o anche antecedentemente in caso di specifica richiesta del Titolare, la documentazione comprovante l’avvenuta esecuzione degli adempimenti privacy;

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={9}>
      i)  informare prontamente il Titolare di ogni questione rilevante ai fini della presente nomina, quali a titolo indicativo: (i) istanze di interessati; (ii) richieste del Garante; (iii) violazioni o messa in pericolo della riservatezza, della completezza o dell’integrità dei dati personali.

      <br/></Text>
      
      <Text mt={4} textAlign="justify" pl={9}>
      j) Fornire, per quanto di competenza, la massima collaborazione al Titolare in caso di istanze avanzate da parte degli interessati, i cui dati sono trattati in esecuzione del Contratto, o in caso di accertamenti o ispezioni effettuate da parte del Garante, nonché in caso di qualsiasi controversia avente ad oggetto la normativa a tutela dei dati personali;  
      <br/></Text>
      
      <Text mt={4} textAlign="justify" pl={9}>
      k) garantire per quanto di competenza l’esecuzione di ogni altra operazione richiesta o necessaria per ottemperare agli obblighi derivanti dalle disposizioni di legge e/o da regolamenti vigenti in materia di protezione dei dati personali;
  
      <br/></Text>
      
      <Text mt={4} textAlign="justify" pl={9}>
      l)  mettere a disposizione del Titolare tutte le informazioni necessarie per dimostrare il rispetto degli obblighi di cui al presente Atto ed alla vigente normativa in tema di tutela dei dati personali, nonché consentire e contribuire alle attività di revisione, comprese le ispezioni che il Titolare, direttamente o avvalendosi di terzi, potrà effettuare per verificare la puntuale osservanza di quanto previsto dalla vigente normativa in materia di protezione dei dati personali nonché delle proprie indicazioni.
      <br/></Text>
      
      <Text mt={4} textAlign="justify" pl={7}>
      5. Con riferimento al trattamento dei dati personali connesso alla fornitura dei servizi di cui al Contratto, il Titolare ai sensi dell’art. 28 comma 2 del <strong>GDPR </strong>autorizza previamente e in via generale  <strong>AIHEALTH srl </strong> ad avvalersi degli ulteriori responsabili di cui riterrà opportuno servirsi. 
      Da parte sua <strong>AIHEALTH srl </strong> informerà il Titolare di eventuali modifiche riguardanti l'aggiunta o la sostituzione di altri responsabili del trattamento, dando così al Titolare del trattamento l'opportunità di opporsi a tali modifiche. Il Responsabile si impegna a che tali ulteriori responsabili posseggano competenze, conoscenze ed esperienze sufficienti per mettere in atto misure tecniche e organizzative idonee a garantire il rispetto delle disposizioni del GDPR. Il Responsabile si impegna, nell’ambito dei contratti o accordi stipulati con gli ulteriori responsabili, a: 

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={10}>
      (i)  vincolare contrattualmente gli ulteriori responsabili al rispetto degli stessi obblighi in materia di protezione dei dati personali assunti dal Responsabile nei confronti del Titolare, ove applicabili e pertinenti rispetto alle attività a questi ultimi affidate; 
  
      <br/></Text>
      <Text mt={4} textAlign="justify" pl={10}>
      (ii)  custodire copia dei predetti contratti, accordi o documenti disciplinanti gli obblighi in materia di protezione dei dati personali, sottoscritti per presa visione ed accettazione da parte degli ulteriori responsabili e fornirne copia al Titolare, su sua richiesta; 

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={10}>
      (iii)  assumere nei confronti del Titolare ogni responsabilità in ordine al rispetto dei predetti obblighi da parte degli ulteriori responsabili;

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={7}>
       6. L’esecuzione delle attività di cui al presente accordo non originano alcun diritto del Responsabile a percepire compensi ulteriori rispetto a quanto previsto per i servizi contrattualmente previsti.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
       7. Il Responsabile si impegna a tenere indenne il Titolare da ogni responsabilità, costo, spesa o altro onere, discendenti da pretese, azioni o procedimenti di terzi a causa della violazione, da parte del Responsabile (o di suoi dipendenti o collaboratori ovvero degli ulteriori responsabili), degli obblighi a suo carico in base alla presente e/o della violazione delle prescrizioni di cui alla vigente normativa in materia di protezione dei dati personali.
      </Text>
      <Text mt={4} textAlign="justify" pl={7}>
      8. Alla cessazione per qualsiasi causa del Contratto, il Responsabile sarà tenuto, a discrezione del Titolare: (i) a restituire al Titolare i dati personali oggetto del trattamento oppure (ii) a provvedere alla loro integrale distruzione, salvi solo i casi in cui la conservazione dei dati sia richiesta da norme di legge ad altri fini (contabili, fiscali, ecc.). In entrambi i casi il Responsabile provvederà a rilasciare al Titolare apposita dichiarazione per iscritto contenente l’attestazione che presso il Responsabile non esiste alcuna copia dei dati personali e delle informazioni di titolarità, fatti salvi i casi in cui la conservazione dei dati sia richiesta da norme di legge ad altri fini (contabili, fiscali, ecc.). Il Titolare si riserva il diritto di effettuare controlli e verifiche volte ad accertare la veridicità della dichiarazione.

      <br/></Text>
      <Text mt={4} textAlign="justify" pl={7}>
      9. La presente nomina va intesa come se fosse stata effettuata all’inizio del rapporto contrattuale tra i contraenti ed avrà durata fino alla cessazione, per qualsivoglia motivo, dello stesso.
      </Text>
    


   
   

  
      
      
      





    </Container>
  );
}